import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "lang--voice" }
const _hoisted_2 = {
  key: 0,
  class: "placeholder"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock("div", {
    class: "container",
    onClick: _cache[6] || (_cache[6] = (...args) => (_ctx.textareaFocus(...args)))
  }, [
    _createVNode("span", _hoisted_1, [
      _createVNode("span", {
        class: ["lang", { 'cream-teal': _ctx.selectedTitle == _ctx.$t('_tabNames.voice') }]
      }, _toDisplayString(_ctx.$t('_labels.language')) + " : " + _toDisplayString(_ctx.languageName), 3),
      _createVNode("span", {
        class: ["lang", { 'cream-teal': _ctx.selectedTitle == _ctx.$t('_tabNames.voice') }]
      }, _toDisplayString(_ctx.$t('_labels.voice')) + " : " + _toDisplayString(_ctx.userSelectedVoice && _ctx.invalidVoiceNames[_ctx.userSelectedVoice] && !_ctx.voiceName.voiceIndex
            ? _ctx.$t(`_invalidVoiceNames.${_ctx.userSelectedVoice}`)
            : _ctx.voiceName.voiceIndex
            ? _ctx.voiceName.voiceIndex + '. ' + _ctx.voiceName.voiceLabel
            : 'Invalid'), 3)
    ]),
    (_ctx.placeholderText)
      ? (_openBlock(), _createBlock("span", _hoisted_2, _toDisplayString(_ctx.placeholder), 1))
      : _createCommentVNode("", true),
    _createVNode("span", {
      ref: "textArea",
      class: "tarea",
      contenteditable: "true",
      label: "text",
      "data-placeholder": _ctx.placeholder,
      onchange: 
        () => {
          _ctx.textArea.parentElement.normalize()
        }
      ,
      onKeyup: _cache[1] || (_cache[1] = ($event) => _ctx.$emit('onKeyUp', $event)),
      onKeypress: _cache[2] || (_cache[2] = ($event) => _ctx.$emit('onKeyPress', $event)),
      onPaste: _cache[3] || (_cache[3] = _withModifiers(
        ($event) => {
          _ctx.handlePaste($event, _ctx.maxLength), _ctx.$emit('onKeyUp', $event)
        }
      , ["prevent"])),
      onCopy: _cache[4] || (_cache[4] = ($event) => _ctx.$emit('onCutOrCopy', $event)),
      onCut: _cache[5] || (_cache[5] = ($event) => _ctx.$emit('onCutOrCopy', $event))
    }, "​ ", 40, ["data-placeholder", "onchange"])
  ]))
}