import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "invalidvoice-message"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_InputField = _resolveComponent("InputField")
  const _component_VoiceTab = _resolveComponent("VoiceTab")
  const _component_Tab = _resolveComponent("Tab")
  const _component_SpeedTab = _resolveComponent("SpeedTab")
  const _component_PitchTab = _resolveComponent("PitchTab")
  const _component_SilenceTab = _resolveComponent("SilenceTab")
  const _component_EmphasisTab = _resolveComponent("EmphasisTab")
  const _component_TabsWrapper = _resolveComponent("TabsWrapper")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.selectedVoice.length === 0)
          ? (_openBlock(), _createBlock("p", _hoisted_1, _toDisplayString(_ctx.invalidVoiceMessage), 1))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (_ctx.routeQuery.projectId !== undefined || _ctx.routeQuery.token !== undefined)
      ? (_openBlock(), _createBlock(_component_InputField, {
          key: 0,
          inputClass: "pp-input",
          inputLabel: "Project Name",
          rootClass: "project-pages",
          projectName: _ctx.projectName,
          maxLength: 60,
          onInput: _ctx.projectNameChange
        }, null, 8, ["projectName", "onInput"]))
      : _createCommentVNode("", true),
    _createVNode(_component_TabsWrapper, {
      textInputLength: _ctx.textInputLength,
      disableApplyPlay: _ctx.disableApplyPlay,
      projectName: _ctx.projectName
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Tab, {
          title: _ctx.$t('_tabNames.voice')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_VoiceTab, { voiceIndexvalue: _ctx.voiceIndexvalue }, null, 8, ["voiceIndexvalue"])
          ]),
          _: 1
        }, 8, ["title"]),
        _createVNode(_component_Tab, {
          title: _ctx.$t('_tabNames.speed')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_SpeedTab, { disableApplyPlay: _ctx.disableApplyPlay }, null, 8, ["disableApplyPlay"])
          ]),
          _: 1
        }, 8, ["title"]),
        _createVNode(_component_Tab, {
          title: _ctx.$t('_tabNames.pitch')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_PitchTab, { disableApplyPlay: _ctx.disableApplyPlay }, null, 8, ["disableApplyPlay"])
          ]),
          _: 1
        }, 8, ["title"]),
        _createVNode(_component_Tab, {
          title: _ctx.$t('_tabNames.silence')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_SilenceTab, {
              userId: _ctx.userId,
              disableSilenceApply: _ctx.disableSilenceApply
            }, null, 8, ["userId", "disableSilenceApply"])
          ]),
          _: 1
        }, 8, ["title"]),
        _createVNode(_component_Tab, {
          title: _ctx.$t('_tabNames.emphasis')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_EmphasisTab, {
              userId: _ctx.userId,
              disableApplyPlay: _ctx.disableApplyPlay
            }, null, 8, ["userId", "disableApplyPlay"])
          ]),
          _: 1
        }, 8, ["title"])
      ]),
      _: 1
    }, 8, ["textInputLength", "disableApplyPlay", "projectName"])
  ]))
}