import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderSlot as _renderSlot, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "tabs" }
const _hoisted_2 = { class: "tabs_header" }
const _hoisted_3 = { class: "textarea__clear-button" }
const _hoisted_4 = { class: "clear__wordcount" }
const _hoisted_5 = { class: "action-buttons" }
const _hoisted_6 = { class: "downloadbtnandtooltip" }
const _hoisted_7 = { class: "modal-ul" }
const _hoisted_8 = { class: "modal-buttons" }
const _hoisted_9 = { class: "errorModalContent" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { key: 2 }
const _hoisted_13 = { key: 3 }
const _hoisted_14 = {
  class: "loader-spin",
  ref: "loader"
}
const _hoisted_15 = { class: "done" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_CustomTooltip = _resolveComponent("CustomTooltip")
  const _component_CustomTextArea = _resolveComponent("CustomTextArea")
  const _component_Button = _resolveComponent("Button")
  const _component_Modal = _resolveComponent("Modal")
  const _component_FuncErrorModal = _resolveComponent("FuncErrorModal")
  const _component_SubscriptionModal = _resolveComponent("SubscriptionModal")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _createVNode("ul", _hoisted_2, [
        (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.tabTitles, (title, index) => {
          return (_openBlock(), _createBlock("li", {
            key: index,
            class: {
          selected: title == _ctx.selectedTitle,
          disabled: _ctx.disableTabs(title, index)
        },
            onClick: $event => (_ctx.tabsNavigation(title)),
            onMouseover: $event => (_ctx.showToolTipIndexed = index),
            onMouseleave: _cache[1] || (_cache[1] = $event => (_ctx.showToolTipIndexed = null))
          }, [
            _createTextVNode(_toDisplayString(title) + " ", 1 /* TEXT */),
            (
            (_ctx.selectedVoice[0]?.voiceCode.includes('Studio') &&
              (title == _ctx.$t('_tabNames.pitch') || title == _ctx.$t('_tabNames.emphasis'))) ||
            (_ctx.selectedVoice[0]?.voiceCode.includes('Journey') && title != _ctx.$t('_tabNames.voice'))
          )
              ? (_openBlock(), _createBlock(_component_CustomTooltip, {
                  key: 0,
                  class: ['hidden', { visible: _ctx.showToolTipIndexed == index }],
                  toolTipText: _ctx.$t('_toolTip.tabsToolTipText'),
                  toolTipType: `btn${index}`,
                  "disable-tooltip": true
                }, null, 8, ["class", "toolTipText", "toolTipType"]))
              : _createCommentVNode("", true)
          ], 42, ["onClick", "onMouseover"]))
        }), 128 /* KEYED_FRAGMENT */))
      ]),
      _renderSlot(_ctx.$slots, "default")
    ]),
    _createVNode("div", _hoisted_3, [
      _createVNode(_component_CustomTextArea, {
        languageName: _ctx.langName(_ctx.language),
        voiceName: _ctx.voiceName(_ctx.selectedVoice) ? _ctx.voiceName(_ctx.selectedVoice) : {},
        userSelectedVoice: _ctx.userData.voice,
        maxLength: _ctx.maxLength,
        onOnKeyUp: _ctx.onKeyUp,
        onOnKeyPress: _ctx.onKeyPress,
        onOnCutOrCopy: _ctx.onCutOrCopy,
        selectedTitle: _ctx.selectedTitle,
        placeholder: _ctx.$t('_labels.placeholder')
      }, null, 8, ["languageName", "voiceName", "userSelectedVoice", "maxLength", "onOnKeyUp", "onOnKeyPress", "onOnCutOrCopy", "selectedTitle", "placeholder"]),
      _createVNode("div", _hoisted_4, [
        _createVNode(_component_Button, {
          text: _ctx.$t('_buttons.clearEffects'),
          buttonType: "clear_effects",
          onOnClick: _ctx.clearEffets
        }, null, 8, ["text", "onOnClick"]),
        _createVNode("p", null, _toDisplayString(_ctx.txtlength) + " / " + _toDisplayString(_ctx.maxLength), 1)
      ])
    ]),
    _createVNode("div", _hoisted_5, [
      _createVNode(_component_Button, {
        text: _ctx.audioPlaying == true && _ctx.actionButtonType == 'play' ? _ctx.$t('_buttons.stop') : _ctx.$t('_buttons.playAll'),
        buttonType: "playbutton",
        isDisabled: _ctx.disabled,
        onOnClick: _cache[2] || (_cache[2] = $event => (_ctx.audioPlaying == false ? _ctx.play(_ctx.selectedLanguage, _ctx.selectedVoice) : _ctx.stopAudio()))
      }, null, 8, ["text", "isDisabled"]),
      _createVNode(_component_Button, {
        text: 
        _ctx.audioPlaying == true && _ctx.actionButtonType == 'play_selection'
          ? _ctx.$t('_buttons.stop')
          : _ctx.$t('_buttons.playSelection')
      ,
        buttonType: "play-selection_button",
        isDisabled: _ctx.disableApplyPlay,
        onOnClick: _cache[3] || (_cache[3] = $event => (_ctx.audioPlaying == false ? _ctx.playSelection() : _ctx.stopAudio()))
      }, null, 8, ["text", "isDisabled"]),
      _createVNode("div", _hoisted_6, [
        _createVNode(_component_Button, {
          text: _ctx.$t('_buttons.download'),
          class: _ctx.checkUser(_ctx.userData, _ctx.projectData, _ctx.projectPagesUserData),
          buttonType: !_ctx.disableDownload ? 'downloadbutton' : 'downloadbutton disabled',
          onOnClick: _cache[4] || (_cache[4] = $event => (_ctx.downloadAudioUrl(_ctx.selectedLanguage, _ctx.selectedVoice, _ctx.projectData))),
          onMouseover: _cache[5] || (_cache[5] = $event => (_ctx.showTooltip = true)),
          onMouseleave: _cache[6] || (_cache[6] = $event => (_ctx.showTooltip = false))
        }, null, 8, ["text", "class", "buttonType"]),
        _createVNode(_component_CustomTooltip, {
          class: ['hidden', { visible: _ctx.showTooltip }],
          toolTipText: _ctx.$t('_toolTip.toolTipText'),
          toolTipType: "downloadbtn",
          "disable-tooltip": !_ctx.routeQuery.projectId && _ctx.routeQuery.token ? true : false
        }, null, 8, ["class", "toolTipText", "disable-tooltip"])
      ]),
      (_ctx.isUserLoggedIn || _ctx.routeQuery.token)
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 0,
            text: _ctx.$t('_buttons.save'),
            buttonType: !_ctx.disablesave ? 'savebutton' : 'savebutton disabled',
            onOnClick: _cache[7] || (_cache[7] = $event => (_ctx.saveData(_ctx.speedValue, _ctx.pitchValue, _ctx.selectedLanguage, _ctx.selectedVoice)))
          }, null, 8, ["text", "buttonType"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode("audio", {
      src: _ctx.playUrl,
      ref: "audioInput",
      autoplay: "",
      muted: _ctx.mute
    }, null, 8, ["src", "muted"]),
    (!_ctx.userData.ID && !_ctx.funcError)
      ? (_openBlock(), _createBlock(_component_Modal, {
          key: 0,
          openModal: _ctx.openModal,
          onCloseModal: _cache[10] || (_cache[10] = $event => (_ctx.closeModal('Modal'))),
          "modal-type": "login-modal"
        }, {
          default: _withCtx(() => [
            _createVNode("h2", null, _toDisplayString(_ctx.$t(`_modalContent.${_ctx.modalContent.modelTitle}`)), 1),
            _createVNode("p", null, _toDisplayString(_ctx.$t(`_modalContent.${_ctx.modalContent.modelDescription}`)), 1),
            _createVNode("ul", _hoisted_7, [
              _createVNode("li", null, _toDisplayString(_ctx.$t('_modalContent.vogFeatures')), 1),
              _createVNode("li", null, _toDisplayString(_ctx.$t('_modalContent.vogFeatures2')), 1),
              _createVNode("li", null, _toDisplayString(_ctx.$t('_modalContent.vogFeatures')), 1)
            ]),
            _createVNode("div", _hoisted_8, [
              _createVNode(_component_Button, {
                text: _ctx.$t('_modalContent.register'),
                buttonType: "registerbutton",
                onOnClick: _cache[8] || (_cache[8] = $event => (
          _ctx.openProjectPages('register', _ctx.speedValue, _ctx.pitchValue, _ctx.selectedLanguage, _ctx.selectedVoice, _ctx.modalContent.ref)
        ))
              }, null, 8, ["text"]),
              _createVNode(_component_Button, {
                text: _ctx.$t('_modalContent.login'),
                buttonType: "loginbutton",
                onOnClick: _cache[9] || (_cache[9] = $event => (_ctx.openProjectPages('login', _ctx.speedValue, _ctx.pitchValue, _ctx.selectedLanguage, _ctx.selectedVoice, 'vog-login')))
              }, null, 8, ["text"])
            ])
          ]),
          _: 1
        }, 8, ["openModal"]))
      : _createCommentVNode("", true),
    _createVNode(_component_FuncErrorModal, {
      "open-modal": _ctx.openfuncErrorModal,
      onCloseModal: _cache[12] || (_cache[12] = $event => (_ctx.closeModal('FuncError'))),
      "modal-type": "error-modal"
    }, {
      default: _withCtx(() => [
        _createVNode("div", _hoisted_9, [
          (_ctx.errorType == 'audio' && _ctx.funcError == 'functionError')
            ? (_openBlock(), _createBlock("h2", _hoisted_10, _toDisplayString(_ctx.$t(`_errorModalContent.${_ctx.funcError}TitleAudio`)), 1))
            : _createCommentVNode("", true),
          (_ctx.errorType == 'download' && _ctx.funcError == 'functionError')
            ? (_openBlock(), _createBlock("h2", _hoisted_11, _toDisplayString(_ctx.$t(`_errorModalContent.${_ctx.funcError}TitleDownload`)), 1))
            : _createCommentVNode("", true),
          (_ctx.funcError == 'punctuationError')
            ? (_openBlock(), _createBlock("h2", _hoisted_12, _toDisplayString(_ctx.$t(`_errorModalContent.${_ctx.funcError}Title`)), 1))
            : _createCommentVNode("", true),
          (_ctx.funcError.length > 1)
            ? (_openBlock(), _createBlock("p", _hoisted_13, _toDisplayString(_ctx.$t(`_errorModalContent.${_ctx.funcError}Description`)), 1))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_component_Button, {
          text: "ok",
          buttonType: "errorOkbutton",
          onOnClick: _cache[11] || (_cache[11] = $event => (_ctx.closeModal('FuncError')))
        })
      ]),
      _: 1
    }, 8, ["open-modal"]),
    _createVNode(_component_SubscriptionModal, {
      "open-modal": _ctx.openSubscriptionModal,
      "modal-type": "subscription-modal",
      "onClose-modal": _cache[14] || (_cache[14] = $event => (_ctx.closeModal('SubscriptionModal')))
    }, {
      default: _withCtx(() => [
        _createVNode("div", null, [
          _createVNode("h2", null, _toDisplayString(_ctx.$t('_modalContent.' + _ctx.subscriptionModelContent.subscriptionModelTitle)), 1),
          _createVNode("p", null, _toDisplayString(_ctx.$t('_modalContent.' + _ctx.subscriptionModelContent.subscriptionModelDescription)), 1),
          _createVNode(_component_Button, {
            text: "Upgrade",
            buttonType: "buyplanbutton",
            "onOn-click": _cache[13] || (_cache[13] = $event => (_ctx.gotoSubscriptionPage()))
          })
        ])
      ]),
      _: 1
    }, 8, ["open-modal"]),
    _withDirectives(_createVNode("div", _hoisted_14, null, 512), [
      [_vShow, _ctx.stateloader]
    ]),
    _withDirectives(_createVNode("div", _hoisted_15, null, 512), [
      [_vShow, _ctx.isdownloaded]
    ])
  ], 64 /* STABLE_FRAGMENT */))
}