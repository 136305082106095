import { createVNode as _createVNode, renderSlot as _renderSlot, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _withDirectives((_openBlock(), _createBlock("div", {
    class: _ctx.modalType,
    ref: "modal"
  }, [
    _createVNode("div", {
      class: _ctx.modalType + '-content'
    }, [
      _createVNode("span", {
        class: "close-modal",
        onClick: _cache[1] || (_cache[1] = $event => (_ctx.$emit('closeModal')))
      }),
      _renderSlot(_ctx.$slots, "default")
    ], 2)
  ], 2)), [
    [_vShow, _ctx.openModal]
  ])
}