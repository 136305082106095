
import { defineComponent, computed, ref, onMounted } from 'vue'
import InputField from '@/components/UI/InputField.vue'
import TabsWrapper from '@/components/TabsWrapper.vue'
import Tab from '@/components/Tab.vue'
import VoiceTab from '@/components/VoiceTab.vue'
import SpeedTab from '@/components/SpeedTab.vue'
import PitchTab from '@/components/PitchTab.vue'
import SilenceTab from '@/components/SilenceTab.vue'
import EmphasisTab from '@/components/EmphasisTab.vue'
import { useI18n } from 'vue-i18n'
import router from '@/router'
import { store } from '@/store'
import { VoicesActionTypes as VoicesActionType } from '@/store/voices/actions'
import { VoicesMutationTypes as VoicesMutationType } from '@/store/voices/mutations'
import { ssmlToHtml } from '@/utils/ssmlToHtml'
import { setEndOfContenteditable } from '@/utils/setEndOfContenteditable'
import { handlePaste } from '@/utils/handlePaste'
import { disableAction } from '@/utils/disableAction'
import { getVoices } from '@/utils/getVoices'
import { removeInvalidTags } from '@/utils/removeInvalidTags'

export default defineComponent({
  components: {
    InputField,
    TabsWrapper,
    Tab,
    VoiceTab,
    EmphasisTab,
    SilenceTab,
    SpeedTab,
    PitchTab
  },
  setup() {
    const { locale } = useI18n({ useScope: 'global' })
    const { t } = useI18n()
    const routeQuery = router.currentRoute.value.query
    const userId = ref('')
    const voiceIndexvalue = ref()
    locale.value = routeQuery.lang ? routeQuery.lang.toString() : 'nl'
    const userData = computed(() => store.state.voices.userData)
    const projectData = computed(() => store.state.voices.projectData)
    const getFilteredVoices = computed(() => store.getters.filteredVoices)
    const selectedVoice = computed(() => store.state.voices.selectedVoice)
    const selectedLanguage = computed(() => store.state.voices.selectedLanguage)
    const textInputLength = ref()
    const disableApplyPlay = ref(true)
    const disableSilenceApply = ref(true)
    const projectName = ref()
    const invalidVoiceMessage = 'The voice you selected is retired. Please choose a different voice from the list.'

    const pitchUiValue = (num) => {
      const uiMin = 0.8
      const uiMax = 1.2
      const apiMax = 20
      const apiMin = -20
      const dataVal = (((num - apiMin) * (uiMax - uiMin)) / (apiMax - apiMin) + uiMin).toFixed(2)
      return dataVal
    }

    const setUserData = async () => {
      if (routeQuery.projectId && userData.value) {
        userId.value = userData.value.ID
        projectName.value = projectData.value.name
        let speedGlobalValue = userData.value.speedPercentage

        if (speedGlobalValue !== null && speedGlobalValue.includes('%')) {
          speedGlobalValue = String(parseInt(speedGlobalValue) / 100)
          await store.commit(VoicesMutationType.SET_VOICE_SPEED_VALUE, speedGlobalValue)
        }

        let pitchGlobalValue = userData.value.pitchNum.toString()

        if (pitchGlobalValue !== null && pitchGlobalValue !== '0' && pitchGlobalValue.includes('%')) {
          pitchGlobalValue = pitchGlobalValue.replace('%', '')
          pitchGlobalValue = pitchUiValue(pitchGlobalValue).toString()
          if (pitchGlobalValue == '0.80' || pitchGlobalValue == '1.20') {
            pitchGlobalValue = pitchGlobalValue.substring(0, 3)
            await store.commit(VoicesMutationType.SET_VOICE_PITCH_VALUE, pitchGlobalValue)
          }
        }
        const isJson = (str) => {
          try {
            JSON.parse(str)
          } catch (e) {
            return false
          }
          return true
        }
        await store.commit(VoicesMutationType.SET_SELECTED_LANGUAGE, userData.value.languageCode)
        await store.commit(VoicesMutationType.SET_TEMP_SELECTED_LANGUAGE, userData.value.languageCode)

        await store.commit(
          VoicesMutationType.SET_SELECTED_VOICE,
          getVoices(selectedLanguage.value, getFilteredVoices.value, t).filter(
            (voice) => voice.voiceCode == userData.value.voice
          )
        )

        voiceIndexvalue.value = selectedVoice.value[0] ? selectedVoice.value[0].value : 0

        const el = document.getElementsByClassName('tarea')[0]
        const validJson = isJson(userData.value.slateData)
        if (validJson != true) {
          el.innerHTML += userData.value.slateData
        } else {
          const textareacontent = await ssmlToHtml(userData.value.insertText, t)
          el.innerHTML += ' ' + textareacontent.innerHTML + '&nbsp;'
        }
        el.parentElement.click()

        setEndOfContenteditable(el)
        const regex = /[\n\s\u200B]+/g
        textInputLength.value = el.textContent.replace(regex, '').length
      } else {
        await store.commit(
          VoicesMutationType.SET_SELECTED_VOICE,
          getVoices(selectedLanguage.value, getFilteredVoices.value, t).filter((voice) => voice.value == 0)
        )
      }
    }
    const initialise = async () => {
      await store.dispatch(VoicesActionType.GET_VOICES_AND_LANGUAGES)
      await store.dispatch(VoicesActionType.GET_USER_DATA)
      await setUserData()
    }

    const disableButton = () => {
      document.addEventListener('selectstart', () => {
        if (document.activeElement.className == 'tarea') {
          disableSilenceApply.value = false
        } else {
          disableSilenceApply.value = true
        }
        document.addEventListener('selectionchange', () => {
          const tarea = document.getElementsByClassName('tarea')[0]
          removeInvalidTags(tarea)
          disableApplyPlay.value = selectedVoice.value[0] ? disableAction() : true
        })
      })
    }

    const projectNameChange = (e) => {
      projectName.value = e.target.value.replace(/<[^>]*>/gi, '')
    }

    onMounted(async () => {
      await initialise()
      disableButton()
    })

    return {
      userId,
      userData,
      projectData,
      textInputLength,
      disableButton,
      disableApplyPlay,
      disableSilenceApply,
      routeQuery,
      projectName,
      projectNameChange,
      voiceIndexvalue,
      handlePaste,
      selectedVoice,
      invalidVoiceMessage
    }
  }
})
