
// import { setEndOfContenteditable } from "@/utils/setEndOfContenteditable";
import { handlePaste } from '@/utils/handlePaste'
import { _invalidVoiceNames as invalidVoiceNames } from '@/lang/translations/en.json'
import { defineComponent, onMounted, ref, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  props: {
    label: {
      type: String,
      default: ''
    },
    maxLength: {
      type: Number,
      default: 2500
    },
    selectedTitle: {
      type: String
    },
    languageName: {
      type: String
    },
    voiceName: {
      type: Object
    },
    placeholder: {
      type: String
    },
    userSelectedVoice: {
      type: String
    }
  },

  setup(props) {
    const textArea = ref(null)
    const { t } = useI18n()
    const regex = /\s+/gi
    const placeholderText = ref(true)

    const textareaFocus = () => {
      textArea.value.focus()
    }

    const changeTagsClass = (selectedTitle) => {
      const selectedClass = selectedTitle.toLowerCase()
      const tabClasses = ['voice', 'speed', 'pitch', 'silence', 'emphasis']

      for (let i = tabClasses.length; i > 0; i--) {
        const elementList = document.getElementsByClassName(tabClasses[i - 1])

        if (t('_tabNames.' + tabClasses[i - 1]).toLocaleLowerCase() == selectedClass) {
          // for the tags matching the selected Tab
          for (let j = elementList.length; j > 0; j--) {
            elementList[j - 1].className += ' cream-teal' // highlight the tags in text-area
          }
        } else {
          for (let j = elementList.length; j > 0; j--) {
            elementList[j - 1].className = elementList[j - 1].className.replace('cream-teal', '') // UN-highlight the tags in text-area
          }
        }
      }
    }

    watchEffect(() => {
      props.selectedTitle
      changeTagsClass(props.selectedTitle)
    })

    onMounted(() => {
      document.addEventListener('click', () => {
        // document.addEventListener("paste", handlePaste);
        if (document.activeElement != textArea.value && textArea.value !== null) {
          if (textArea.value.innerText.length > 1) {
            placeholderText.value = false
            // silenceApply.disabled = true;
          } else {
            placeholderText.value = true
          }
        } else {
          placeholderText.value = false
        }
      })
    })

    return {
      textareaFocus,
      textArea,
      regex,
      placeholderText,
      handlePaste,
      invalidVoiceNames
    }
  }
})
