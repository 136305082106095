import { _invalidVoiceNames } from "@/lang/translations/en.json"

const getVoices = (selectedLanguage, getFilteredVoices, t) => {
    const voices = getFilteredVoices.filter(voice => !_invalidVoiceNames[voice.voiceCode] && !t(selectedLanguage + '.' + voice.voiceCode).includes(selectedLanguage)).map((voice, i) => {
        return {
            value: i,
            label: t(selectedLanguage + '.' + voice.voiceCode),
            gender: voice.label,
            voiceCode: voice.voiceCode,
            selected: voice.selected
        }
    })
    return voices
}
export { getVoices }